/**
 * @param {number} number - Value to check.
 * @returns {boolean} Return true if number is valid even if number = 0.
 */
export const isTruthyOrZero = number => number === 0 || number;

/**
 * Check if a text is in latin alphabet.
 *
 * @param {string} text - Text to check.
 * @returns {boolean} - Result of the test.
 */
export const isForeignLanguage = (text) => {
  /* eslint-disable-next-line no-control-regex */
  const rforeign = /[^\x00-\x7F\u00C0-\u00FF\u0100-\u017F\u0180-\u024F]/;
  return !rforeign.test(text);
};

const htmlEmpty = '<p></p>\n';

export const isHtmlContentEmpty = text => (text === htmlEmpty);

/**
 * Return specific param from url.
 *
 * @param {string} queryString - Search part of the url.
 * @param {string} param - Name of the param to get.
 * @returns {string} - Return selected param.
 */
export const getParamInUrl = (queryString, param) => {
  const urlParams = new URLSearchParams(queryString);
  return urlParams.get(param);
};

/**
 * Return the correct color according the completion rate of a product.
 *
 * @param {number} completionRate - The value of the completion rate.
 *
 * @returns {string} - Return color.
 */
export const getCompletionRateColor = (completionRate) => {
  if (completionRate <= 30) {
    return 'red';
  }

  if (completionRate > 30 && completionRate < 80) {
    return 'orange';
  }

  if (completionRate >= 80) {
    return 'green';
  }

  return '';
};

export const getTimeFromDateString = (dateString = null) => (dateString ? `${dateString.split('T')[1].split('+')[0].split(':')[0]
}:${dateString.split('T')[1].split('+')[0].split(':')[1]}` : '');
