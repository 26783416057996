import agent from 'services/http';

import { mapSearchResultsFromServer } from './searches.mapper';

/**
 * Perform an autocomplete search on the repository.
 *
 * @async
 * @param {string} title - Full title or contains.
 * @param {string} context - Context of the search.
 * @returns {object} - Result.
 */
export const searchByTitle = async (title, context) => {
  const { body } = await agent
    .get('/api/searches/titles')
    .query({
      q: title,
      context,
    });

  return mapSearchResultsFromServer(body);
};

/**
 * Perform a search by uuid on the repository.
 *
 * @async
 * @param {string} uuid - Full title or contains.
 * @param {string} context - Context of the search.
 * @returns {object} - Result.
 */
export const searchByUuid = async (uuid, context) => {
  const { body } = await agent
    .get('/api/searches/uuid')
    .query({
      q: uuid,
      context,
    });

  return mapSearchResultsFromServer(body);
};

/**
 * Perform product title existence.
 *
 * @param {string} title - Full title.
 * @param {boolean} isComponent - True if it's a component, false if it's a product.
 * @returns {object} - Result.
 */
export const checkTitle = async (title, isComponent) => {
  const { body } = await agent
    .get(!isComponent ? '/api/searches/productTitle' : '/api/searches/componentTitle')
    .query({
      q: title,
      isMatch: true,
    });

  return mapSearchResultsFromServer(body);
};

/**
 * Search a product title in the product list.
 *
 * @param {string} title - Full title.
 * @returns {object} - Result.
 */
export const getProductTitlesStartWith = async (title) => {
  const { body } = await agent
    .get('/api/searches/productTitle')
    .query({
      q: title,
      isMatch: false,
    });

  return mapSearchResultsFromServer(body);
};

/**
 * Check if the title of the current product in form already exist.
 *
 * @param {string} title - Title.
 * @param {string} currentProductId - Id of the current product in form.
 * @param {boolean} isComponent - True if it's a component, false if it's a product.
 * @returns {object} - Result filtered on id.
 */
export const checkIfProductTitleExists = async (title, currentProductId, isComponent = false) => {
  const result = await checkTitle(title.trim(), isComponent);

  return result.filter(t => t.id !== currentProductId);
};
