import React from 'react';
import ReactDOM from 'react-dom';

import App from 'react/App';
import AppWrapper from 'react/AppWrapper';

import store from 'redux/store';

import { selectUserLanguage } from 'redux/users/selectors';

import bootstrapState from 'bootstrap/state';
import { history } from 'services/router';
import { importLocaleBundle } from 'services/i18n/i18n.service';

import logging from './utils/logging';

export const startApp = async () => {
  await Promise.all([
    bootstrapState(),
  ]);

  // Disable unused logs
  logging();

  const state = store.getState();

  const locale = selectUserLanguage(state);
  const localeBundle = await importLocaleBundle(locale);

  ReactDOM.render(
    <AppWrapper
      store={store}
      history={history}
      localeBundle={localeBundle}
    >
      <App />
    </AppWrapper>,
    document.getElementById('app'),
  );
};
